import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "eurofortune",
  casinoId: 84,
  GA_TRACKING:'G-X4CSZF6W0P',

  rivalChatGroupName: "EuroFortune",
  prettyName: "EuroFortune",
  contactEmail: "support@eurofortunegames.com",
  docsEmail: "documents@eurofortunegames.email",


  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/eurofortune/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/eurofortune/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/eurofortune/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/eurofortune/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/eurofortune/promo.png"}
  ],


  //SEO
  metaDescription: "Join Online casino EuroFortune Casino featuring over 250 games. Play slots and win live dealer games. Real cash prize & 300% welcome bonus, secure payments, and a rewarding VIP program. Spin with a secure gaming (iGaming) casino and gamble easy.",
  logoName: "eurofortune-casino-online-casino-logo.png",
  logoAltText: "EuroFortune Casino online logo with gold and white text and four-leaf clover symbol popular for European slots jackpots and casino rewards."


};


